import { useEffect, useRef } from "react";
import { Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import api from "../../../constants/api";
import useAxios from "../../../hooks/useAxios";
import { useAddMultipleDocsToQueue } from "../../../hooks/useDocumentsUpdate";
import { documentStatus, documentAccessLevels } from "../../../constants";
import { useState } from "react";
import errorMessages from "../../../utils/messages/errorMessages";
import infoMessages from "../../../utils/messages/infoMessages";
import { AccessLevelButton } from "./AccessLevelButton";
import { DocumentEntityTypes } from "./DocumentEntityTypes";
import { knownEntityTypes } from "../../../constants/entityTypes";
import SkeletonLoader from "../../common/loaders/SkeletonLoader";
import {
  useMessageBoxError,
  useMessageBoxSuccess,
} from "../../../hooks/useMessageBox";
import successMessages from "../../../utils/messages/successMessages";

const { insert_url, insert_url_or_remove } = errorMessages;
const { QUEUE } = documentStatus;
const defaultCheckedList = Object.values(knownEntityTypes);

/**
 * Form component for importing html url document
 *
 * @function
 * @returns React Virtual DOM
 */
const URLImport = ({ handleCancel }) => {
  const [{ loading, result, error, reqId }, uploadURL] = useAxios(null, "POST");
  const [form] = Form.useForm();
  const docRef = useRef({});
  const [accessLevelType, setAccessLevelType] = useState(
    documentAccessLevels.ORGANISATION
  );
  const [entityList, setEntityList] = useState([...defaultCheckedList]);

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useAddMultipleDocsToQueue(result, docRef, reqId);
  useMessageBoxSuccess(result, successMessages.url_queued);
  useMessageBoxError(error);

  const handleSubmit = ({ urls = [], ...otherFields }) => {
    urls.forEach((urlOrTitle, i) => {
      docRef.current[i] = {
        title: urlOrTitle.title || urlOrTitle.url,
        status: QUEUE,
      };
      uploadURL(`${api.UPLOAD_URL}?reqId=${i}`, {
        ...otherFields,
        url: urlOrTitle.url,
        title: urlOrTitle.title,
        access_level: accessLevelType,
        entity_list: entityList,
        all_entity_list: defaultCheckedList,
      });
    });
    form.resetFields();
  };

  const getDocumentAccessType = (docType) => {
    setAccessLevelType(docType);
  };

  const getCheckedEntityList = (list) => {
    const actualEntityTypes = list.map(
      (entityType) => `${knownEntityTypes[entityType]}`
    );
    setEntityList(actualEntityTypes);
  };

  const handleClick = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Form
      form={form}
      name="dynamic_form_item"
      onFinish={handleSubmit}
      layout="vertical"
    >
      <div style={{ minHeight: "94px" }}>
        <SkeletonLoader.DocumentCard
          loading={loader}
        ></SkeletonLoader.DocumentCard>
        <Form.List name="urls">
          {(fields, { add, remove }, { errors }) => {
            if (!fields.length) add();
            return (
              <>
                {fields.map(({ name, key, ...restFields }, index) => (
                  <Form.Item key={key}>
                    <Form.Item
                      label={
                        <span
                          style={{
                            color: "#344054",
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "18px",
                            fontFamily: "inter,sans-serif",
                          }}
                        >
                          {infoMessages.title}
                        </span>
                      }
                      required={false}
                      {...restFields}
                      name={[name, "title"]}
                      rules={[
                        {
                          message: "Insert a url title",
                        },
                      ]}
                      style={{ margin: "2px 1px 12px 2px" }}
                    >
                      <Input className="import-url-fields" />
                    </Form.Item>
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                      }}
                      align="baseline"
                      className="url-field"
                    >
                      <Form.Item
                        name={[name, "url"]}
                        {...restFields}
                        validateTrigger={["onChange", "onBlur"]}
                        label={
                          <span
                            style={{
                              color: "#344054",
                              fontWeight: 500,
                              fontSize: "12px",
                              lineHeight: "18px",
                              fontFamily: "inter,sans-serif",
                            }}
                          >
                            {infoMessages.url}
                          </span>
                        }
                        required={false}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              index === 0 ? insert_url : insert_url_or_remove,
                          },
                        ]}
                        style={{ width: "100%" }}
                      >
                        <Input
                          suffix={
                            fields.length > 1 ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : null
                          }
                          className="import-url-fields"
                        />
                      </Form.Item>
                      <Form.Item style={{ display: "none" }}>
                        <Button
                          type="default"
                          size="large"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          style={
                            fields.length > 1
                              ? {
                                  margin: "4px",
                                  transform: "scale(1.17)",
                                  position: "relative",
                                  top: "8px",
                                }
                              : {}
                          }
                          className="mb-0"
                        ></Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                ))}
              </>
            );
          }}
        </Form.List>
      </div>
      <AccessLevelButton getDocumentAccessType={getDocumentAccessType} />
      <DocumentEntityTypes checkedEntityList={getCheckedEntityList} />
      <div className="import-button-head">
        <Button block className="import-button-cancel" onClick={handleClick}>
          Cancel
        </Button>
        <Button
          htmlType="submit"
          loading={loading}
          block
          className="import-button-import"
        >
          Import
        </Button>
      </div>
    </Form>
  );
};

export default URLImport;
