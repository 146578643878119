import { Tabs } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import URLImport from "./URLImport";
import FileImport from "./FileImport";
import TextImport from "./TextImport";
import { useDocumentsReloadInterval } from "../../../hooks/useReload";
import { documentStatus } from "../../../constants";

const { COMPLETED, REVIEWING, FOR_REVIEW, QUEUE } = documentStatus;

const { TabPane } = Tabs;

const URL_PANE = "URL";
const TEXT_PANE = "Text";
const FILE_PANE = "File";

const setTabTitle = (title) => <>{title}</>;

const ImportTabs = ({ handleCancel }) => {
  useDocumentsReloadInterval([QUEUE, FOR_REVIEW, REVIEWING, COMPLETED]);

  return (
    <>
      <h2
        style={{
          backgroundColor: "#F9FAFB",
          width: "14%",
          borderRadius: "28px",
          padding: "10px",
        }}
      >
        <UploadOutlined
          style={{
            color: "#475467",
            backgroundColor: "#F2F4F7",
            borderRadius: "28px",
            padding: "5px",
          }}
        />
      </h2>
      <h2 className="import-modal-heading">
        <b className="pane-title"> Import </b>
      </h2>
      <Tabs
        defaultActiveKey={URL_PANE}
        className="mr-10"
        tabBarStyle={{ border: "none" }}
      >
        <TabPane
          tab={setTabTitle(URL_PANE)}
          key={URL_PANE}
          className="tabs-title"
        >
          <URLImport handleCancel={handleCancel} />
        </TabPane>
        <TabPane
          tab={setTabTitle(TEXT_PANE)}
          key={TEXT_PANE}
          className="tabs-title"
        >
          <TextImport handleCancel={handleCancel} />
        </TabPane>
        <TabPane
          tab={setTabTitle(FILE_PANE)}
          key={FILE_PANE}
          className="tabs-title"
        >
          <FileImport handleCancel={handleCancel} />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ImportTabs;
