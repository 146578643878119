import { Link } from "react-router-dom";
import { Layout, Button } from "antd";
import { FileTextOutlined, CheckOutlined } from "@ant-design/icons";
import logo from "../../../assets/images/elemendar-logo.png";
import readLogo from "../../../assets/images/read-logo-transparent.png";
import routes from "../../../constants/routes";
import HeaderOptions from "./HeaderOptions";
import ContinuousLoader from "../loaders/ContinuousLoader";
import ImportDocumentModal from "./ImportDocumentModal";
import { useState } from "react";
import { documentFilters, documentStatus } from "../../../constants";

const defaultDocs = {
  [documentStatus.FOR_REVIEW]: [],
  [documentStatus.REVIEWING]: [],
  [documentStatus.COMPLETED]: [],
  [documentStatus.QUEUE]: [],
  [documentFilters.SEARCH]: "",
  [documentFilters.IMPORTED_BY]: "",
  [documentFilters.COLLECTION]: "",
  [documentFilters.ACCESS]: "",
};

const Header = ({ loader }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [documentData, setDocumentData] = useState(defaultDocs);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout.Header className="app-header">
      <div className="app-title">
        <div className="app-elemendar-logo">
          <img src={logo} alt="ELEMENDAR" width="70px" />
        </div>
        <div className="app-read-logo">
          <Link to={routes.HOME}>
            <img src={readLogo} alt="READ" width="110px" />
          </Link>
        </div>
        <div id="top-relationship-anchor"></div>
      </div>
      <div className="import-header">
        <Button className="import-button" onClick={showModal}>
          <p className="import-text">Import</p>
          <FileTextOutlined className="import-document-icon" />
        </Button>

        <ImportDocumentModal
          isVisible={isModalVisible}
          handleOk={handleModalClose}
          handleCancel={handleModalClose}
          documentData={documentData}
          setDocumentData={setDocumentData}
        />
      </div>
      <HeaderOptions />
      <ContinuousLoader
        className="header-loader"
        loading={loader.loading}
        height="5px"
      />
    </Layout.Header>
  );
};

export default Header;
