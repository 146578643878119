import { Button, Popover, Divider, Menu, Avatar, Badge } from "antd";
import {
  CaretDownFilled,
  CaretRightFilled,
  LogoutOutlined,
  UserOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import UserAvatar from "../widgets/UserAvatar";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useState } from "react";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";

import ActionTip from "../widgets/ActionTip";

const HeaderOptions = () => {
  const [dropDownOpened, setDropDownOPened] = useState(false);
  const history = useHistory();

  const { userDetails, auth } = useAuthContext();

  const popoverProps = {
    placement: "bottomRight",
    content: <Options userDetails={userDetails} auth={auth} />,
    trigger: "click",
  };

  if (!(auth && auth.authenticated)) return null;
  return (
    <div className="header-user-info">
      <div className="header-user-avatar">
        <ActionTip title="Entity search">
          <Button
            style={{ border: 0 }}
            type="ghost"
            shape="circle"
            icon={<SearchOutlined />}
            size="large"
            onClick={() => history.push(routes.SEARCH)}
          />
        </ActionTip>
        <Popover
          {...popoverProps}
          onVisibleChange={(isOpened) => setDropDownOPened(isOpened)}
        >
          <UserAvatar name={userDetails.fullName} />
          <Button type="text" size="small">
            {userDetails.firstName}
          </Button>
          <Button type="text" size="small" style={{ marginLeft: "-12px" }}>
            {!dropDownOpened && <CaretRightFilled style={{ color: "#777" }} />}
            {dropDownOpened && <CaretDownFilled style={{ color: "#777" }} />}
          </Button>
        </Popover>
      </div>
    </div>
  );
};

export default HeaderOptions;

const Options = ({ userDetails, auth }) => {
  const history = useHistory();

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Badge
          status="success" // Green dot
          offset={[-2, 30]} // Adjust the values to position at bottom-right of the circle
        >
          <Avatar
            style={{
              verticalAlign: "middle",
              color: "black",
              fontWeight: "600",
            }}
            size="large"
          >
            {userDetails.fullName
              .split(" ")
              .slice(0, 2)
              .map((word) => word[0])
              .join("")}
          </Avatar>
        </Badge>

        <div className="" style={{ textAlign: "start", marginLeft: "1rem" }}>
          <strong>{userDetails.fullName}</strong> <br />
          <small>{userDetails.email}</small> <br />
          {/* <small>{userDetails.organizationId}</small> */}
        </div>
      </div>
      {/* <Divider style={{ margin: "10px" }} /> */}
      <Divider />
      <div className="user-options">
        <Menu>
          <Menu.Item
            key={routes.PROFILE}
            className="list-item"
            onClick={() => history.push(routes.PROFILE)}
            icon={<UserOutlined />}
          >
            Profile
          </Menu.Item>
          {/* {userRoles.includes(appRoles.MANAGE_ORG_SETTINGS) && (
            <Menu.Item
              key={routes.ORGANISATION}
              className="list-item"
              onClick={() => history.push(routes.ORGANISATION)}
              icon={<BankOutlined />}
            >
              Organisation
            </Menu.Item>
          )}
          {performanceDashboardSupported() && (
            <Menu.Item
              key={routes.PERFORMANCE_DASHBOARD}
              className="list-item"
              onClick={() => history.push(routes.PERFORMANCE_DASHBOARD)}
              icon={<BarChartOutlined />}
            >
              Performance
            </Menu.Item>
          )}
          <Menu.Item
            key={routes.RULES_MANAGER}
            className="list-item"
            onClick={() => history.push(routes.RULES_MANAGER)}
            icon={<AppstoreAddOutlined />}
          >
            Rules
          </Menu.Item>
          {userRoles.includes(appRoles.ORGANISATION_METADATA) && (
            <Menu.Item
              key={routes.METADATA}
              className="list-item"
              onClick={() => history.push(routes.METADATA)}
              icon={<ProfileOutlined />}
            >
              Metadata
            </Menu.Item>
          )}
          {userRoles.includes(appRoles.MANAGE_ORG_COLLECTIONS) && (
            <Menu.Item
              key={routes.COLLECTIONS}
              className="list-item"
              onClick={() => history.push(routes.COLLECTIONS)}
              icon={<MergeCellsOutlined />}
            >
              Collections
            </Menu.Item>
          )} */}
          {/* <Menu.Divider style={{ margin: "10px" }} /> */}
          <Menu.Item
            key="logout"
            className="list-item"
            onClick={() => auth.logout()}
            icon={<LogoutOutlined />}
          >
            Logout
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};
