import React from "react";
import { Modal } from "antd";
import { DocumentProvider } from "../../../contexts/DocumentContext";
import ImportPane from "../../pages/documents/ImportPane";

const ImportDocumentModal = ({
  isVisible,
  handleOk,
  handleCancel,
  documentData,
  setDocumentData,
}) => {
  const overlayStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  };

  return (
    <Modal
      className="modal-main-top"
      visible={isVisible}
      onCancel={handleCancel}
      onOk={handleOk}
      footer={null}
      closable={false}
      maskStyle={overlayStyle}
      style={{ borderRadius: "12px" }}
    >
      <DocumentProvider value={{ documentData, setDocumentData }}>
        <ImportPane handleCancel={handleCancel} />
      </DocumentProvider>
    </Modal>
  );
};

export default ImportDocumentModal;
