import React, { useEffect, useState } from "react";
import { ReactComponent as ReportIcon } from "../../../assets/svgs/entities/Report.svg";
import { ReactComponent as Performance } from "../../../assets/svgs/entities/performance.svg";
import { ReactComponent as Icon1 } from "../../../assets/svgs/entities/Icon_1.svg";

import { ReactComponent as Users } from "../../../assets/svgs/entities/users.svg";
import { ReactComponent as Slider } from "../../../assets/svgs/entities/sliders.svg";
import { ReactComponent as Metadata } from "../../../assets/svgs/entities/metadata.svg";
import { useAuthContext } from "../../../contexts/AuthContext"; // Import Auth Context
import { useHistory } from "react-router";
import { performanceDashboardSupported } from "../../../utils/helpers";
import routes from "../../../constants/routes";
import { appRoles } from "../../../constants";
import Sider from "antd/lib/layout/Sider";
import { Link } from "react-router-dom";
import readLogo from "../../../assets/svgs/entities/READ.svg";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { Divider, Menu } from "antd";

function getItem(label, key, icon, onClick, children) {
  return {
    key,
    icon,
    label,
    onClick,
    children,
  };
}

const useLeftSidebar = () => {
  const history = useHistory();
  const { auth } = useAuthContext(); // Get auth context
  const userRoles = auth && auth.realmAccess ? auth.realmAccess.roles : []; // Get user roles

  // Define the sidebar items with onClick handlers and role checks
  const items = [
    getItem("Reports", "1", <ReportIcon className="sidebar-icon" />, () =>
      history.push(routes.DOCUMENTS)
    ),
    performanceDashboardSupported() &&
      getItem(
        "Performance",
        "2",
        <Performance className="sidebar-icon" />,
        () => history.push(routes.PERFORMANCE_DASHBOARD)
      ),
    userRoles.includes(appRoles.MANAGE_ORG_COLLECTIONS) &&
      getItem("Buckets", "3", <Icon1 className="sidebar-icon" />, () =>
        history.push(routes.COLLECTIONS)
      ),
    getItem("Rules", "4", <Slider className="sidebar-icon" />, () =>
      history.push(routes.RULES_MANAGER)
    ),
    userRoles.includes(appRoles.MANAGE_ORG_SETTINGS) && // Role check for Organisation
      getItem("Organisation", "5", <Users className="sidebar-icon" />, () =>
        history.push(routes.ORGANISATION)
      ),
    userRoles.includes(appRoles.ORGANISATION_METADATA) &&
      getItem("Metadata", "6", <Metadata className="sidebar-icon" />, () =>
        history.push(routes.METADATA)
      ),
  ].filter(Boolean); // Filter out any false values (undefined items)

  return items; // Return the items array
};

const LeftSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const LeftSidebar = useLeftSidebar();
  const history = useHistory();
  const [selectedKey, setSelectedKey] = useState("1");

  useEffect(() => {
    // console.log(history.location.pathname)
    switch (`${history.location.pathname}`) {
      case routes.DOCUMENTS:
        setSelectedKey("1");
        break;
      case routes.PERFORMANCE_DASHBOARD:
        setSelectedKey("2");
        break;
      case routes.COLLECTIONS:
        setSelectedKey("3");
        break;
      case routes.RULES_MANAGER:
        setSelectedKey("4");
        break;
      case routes.ORGANISATION:
        setSelectedKey("5");
        break;
      case routes.METADATA:
        setSelectedKey("6");
        break;
      default:
        setSelectedKey("1"); // Default case
    }
  }, [history, history.location.pathname]); // Added history to the dependency array

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Sider
      collapsed={collapsed} // Control the collapsed state manually
      style={{ background: "black" }}
      trigger={null} // Removes the slider arrow
    >
      <div
        className="app-read-logo"
        style={{
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {!collapsed && (
          <Link to={routes.HOME}>
            <img src={readLogo} alt="READ" width="110px" />
          </Link>
        )}
        {!collapsed ? (
          <MdArrowBackIos
            onClick={toggleCollapsed} // Toggle collapse when icon is clicked
            style={{ cursor: "pointer", color: "white" }}
          />
        ) : (
          <MdArrowForwardIos
            onClick={toggleCollapsed} // Toggle collapse when icon is clicked
            style={{ cursor: "pointer", color: "white", marginBlock: "0.8rem" }}
          />
        )}
      </div>
      <Divider style={{ borderColor: "#1E2024" }} />
      <Menu
        theme="dark"
        selectedKeys={[selectedKey]} // Use selectedKeys instead of defaultSelectedKeys
        mode="inline"
        style={{ background: "black", color: "white" }}
      >
        {LeftSidebar.map((item) => (
          <Menu.Item
            key={item.key}
            icon={item.icon}
            onClick={() => {
              item.onClick(); // Trigger route change
              setSelectedKey(item.key); // Set selected key manually
            }}
          >
            {!collapsed && item.label}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default LeftSidebar;
